import style from './index.module.scss'
import { useEffect } from "react"
import '../../styles/animate.min.css'
import { WOW } from 'wowjs'
import Contenttab from '@/components/Contenttab'
import Banner from "@/components/Banner"

function Solution() {
    useEffect(() => {
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true,
            live: true
        });
        wow.init()
    }, [])
    return (
        <div className={style.sol}>
            <Banner img={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/pay.png'} ></Banner>
            <Contenttab tabArr={['威支付立牌码', '支付电子小票', '威支付商户管家', '威支付伙伴管家',]}></Contenttab>
            <div className='code'>
                <div className='left wow fadeInLeft' style={{ marginRight: 120 }}>
                    <div className='f_s36 ma_bottom20 ma_top40'>威支付立牌码</div>
                    <div style={{ color: '#333' }}>一个码牌多种支付，支持使用微信，支付宝，云闪付<br />扫码付款，让用户付款更省心</div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-kanban  ma_right20'></div>
                        <div>
                            <div className='f_s18'>一码多扫</div>
                            <div>一个立牌码，支持多种付款方式，解放商家收银台</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-renqun  ma_right20'></div>
                        <div>
                            <div className='f_s18'>一码多用</div>
                            <div>为减少码牌制作成本，支持平台创建码牌回收，回收利用给不同商家收银员绑定使用</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-tiaobo  ma_right20'></div>
                        <div>
                            <div className='f_s18'>经济适用</div>
                            <div>经济实用的聚合码牌，采用纸质，塑料，亚克力等材质，制作成本低</div>
                        </div>
                    </div>
                </div>
                <img className='wow fadeInRight' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/code.png'} alt="" style={{ height: 500 }} />
            </div>
            <div className='code'>
                <img className='wow fadeInLeft' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/xiaopiao.png'} alt="" style={{ height: 500, marginRight: 120 }} />
                <div className='left wow fadeInRight'>
                    <div className='f_s36 ma_bottom20'>支付电子小票</div>
                    <div style={{ color: '#333' }}>电子小票与纸质小票都是线下购物的有效凭证，是纸质小票的电子化形态，微信和支付宝电子小票易于存储、查看，便于消费追溯、获取售后服务等</div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy9  ma_right20'></div>
                        <div>
                            <div className='f_s18'>方便快捷</div>
                            <div>告别传统纸质小票，手机端就能查看每笔支付小票</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy14  ma_right20'></div>
                        <div>
                            <div className='f_s18'>易于存储</div>
                            <div>传统小票不仅容易丢失，还难以存储，有了微信电子小票无需再担心存储丢失问题</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy2  ma_right20'></div>
                        <div>
                            <div className='f_s18'>节约成本</div>
                            <div>传统购物中心，社区超市，纸质小票成本日益攀升，有了微信电子小票大大节省了纸质小票成本</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy4  ma_right20'></div>
                        <div>
                            <div className='f_s18'>绿色环保</div>
                            <div>纸质小票凭证仅用于用户购买后核对，多数情况会被当做垃圾处理，有了微信电子小票即方便核对，又不容易丢失</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='code'>
                <div className='left wow fadeInLeft' style={{ marginRight: 120 }}>
                    <div className='f_s36 ma_bottom20'>威支付商户管家</div>
                    <div style={{ color: '#333' }}>一款提供给商家移动端管理支付业务工具，包含扫码收款，交易查询，对账查询，结算查询，基础管理等</div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-shoukuanma  ma_right20'></div>
                        <div>
                            <div className='f_s18'>扫码收款</div>
                            <div>支持微信，支付宝，云闪付主扫和被扫收款</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy16  ma_right20'></div>
                        <div>
                            <div className='f_s18'>交易查询</div>
                            <div>支持查询实时交易订单，历史交易订单，退款订单</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy15  ma_right20'></div>
                        <div>
                            <div className='f_s18'>语音播报</div>
                            <div>支付语音播报，支持普通话，粤语，英语多种语言播报</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy11  ma_right20'></div>
                        <div>
                            <div className='f_s18'>对账结算</div>
                            <div>支持查询上游结算金额，以及结算打款查询</div>
                        </div>
                    </div>
                </div>
                <img className='wow fadeInRight' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/mer.png'} alt="" style={{ height: 500 }} />
            </div>
            <div className='code'>
                <img className='wow fadeInLeft' src={'https://vpay-static.oss-cn-shenzhen.aliyuncs.com/static/official/assets/service/huoban.png'} alt="" style={{ height: 500, marginRight: 120, marginBottom: 140 }} />
                <div className='left wow fadeInRight'>
                    <div className='f_s36 ma_bottom20'>威支付伙伴管家</div>
                    <div style={{ color: '#333' }}>一款协助代理商掌中展业客户端，支持但不局限于代理商进行商户管理，门店管理，进件管理，交易查询，结算查询，返佣管理等业务</div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy6  ma_right20'></div>
                        <div>
                            <div className='f_s18'>移动进件</div>
                            <div>支持代理商移动端协助商户进件，进件资料支持OCR识别，告别传统手工录入</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy1  ma_right20'></div>
                        <div>
                            <div className='f_s18'>实名认证</div>
                            <div>支持代理商移动端协助商户实现微信和支付宝实名认证，助力商户一步到位开通支付</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy10  ma_right20'></div>
                        <div>
                            <div className='f_s18'>对账结算</div>
                            <div>支持代理商协助商户移动端查询交易数据和结算数据，再也不用担心差错账，结算不打款</div>
                        </div>
                    </div>
                    <div className='flex ma_top50 align_center ma_left10'>
                        <div className=' f_s28 iconfont  te_green i-RectangleCopy4  ma_right20'></div>
                        <div>
                            <div className='f_s18'>返佣管理</div>
                            <div>移动端完成整个返佣数据核对，结算流程，简单便捷易于操作</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Solution