// 页面底部模块
import style from './index.module.scss'

function Pagebottom() {
    return (
        <div className={style.box}>
            <div className='page_bottom'>
                <div className='flex'>
                    <dl>
                        <dt className='fw_bold f_s16 ma_bottom30'>关于我们</dt>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="http://www.bw8848.com/about_3.html">智百威简介</a></dd>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="http://www.bw8848.com/abouts_4.html">智百威大事件</a></dd>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="http://www.bw8848.com/abouts_60.html">智百威荣誉</a></dd>
                    </dl>
                    <dl>
                        <dt className='fw_bold f_s16 ma_bottom30'>友情链接</dt>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="https://www.yun8848.com/Login.aspx">威云POS</a></dd>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="http://www.bw8848.com/product_40/340.html">威店</a></dd>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="http://www.bw8848.com/news_19/715.html">电子发票</a></dd>
                    </dl>
                    <dl>
                        <dt className='fw_bold f_s16 ma_bottom30'>后台登录</dt>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="https://merchant.zbwpay.com/">商户管理平台</a></dd>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="https://agent.zbwpay.com/">伙伴管理平台</a></dd>
                        <dd><a className='te_D clerk' rel="noreferrer" target="_blank" href="https://isv.zbwpay.com/">运营管理平台</a></dd>
                    </dl>
                    <dl>
                        <dt className='fw_bold f_s16 ma_bottom30'>客服帮助</dt>
                        <dd className='te_D clerk'>电话：0755-82807556 82807558</dd>
                        <dd className='te_D clerk'>传真：0755-82781568</dd>
                        <dd className='te_D clerk'>(工作时间：09:00-22:00)</dd>
                    </dl>
                </div>
                <div className='flex'>
                    <div className='ma_right50'>
                        <img src={require('../../assets/pagebottom/1.png')} alt="" style={{ height: 120, width: 120 }} />
                        <div className='ma_top10 te_D te_center'>智百威公众号</div>
                    </div>
                    <div className='ma_right50'>
                        <img src={require('../../assets/pagebottom/2.png')} alt="" style={{ height: 120, width: 120 }} />
                        <div className='ma_top10 te_D te_center'>威支付公众号</div>
                    </div>
                    <div className='flex flex_column align_center'>
                        <img src={require('../../assets/pagebottom/3.png')} alt="" style={{ height: 68, width: 130 }} />
                        <div className='ma_top10 te_D te_center'>全国售前咨询热线</div>
                        <div className='ma_top10 fw_bold f_s24 te_blue'>400-613-8848</div>
                    </div>
                </div>
            </div>
            <div className='absolute_bottom te_center'>
                <div className='te_D'>Copyright © 2022 深圳市智百威科技发展有限公司 | 增值电信业务经营许可证编号：合字B2-20230109  | <a className='te_D clerk' rel="noreferrer" target="_blank" href="https://beian.miit.gov.cn/">备案号：粤ICP备10062811号</a>  |  <a className='te_D clerk' rel="noreferrer" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402005318">粤公网安备：44030402005318号</a> </div>
            </div>
        </div>

    )
}

export default Pagebottom