// 导入路由
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import Home from '@/pages/Home/index';
import Payment from './pages/Payment';
import Solution from './pages/Solution';
import Notice from './pages/Notice';
import IntelligentDevice from './pages/IntelligentDevice';
// 引入头部底部
import Pagehead from "@/components/Pagehead"
import Pagebottom from "@/components/Pagebottom"
// 路由跳转后回到页面顶部
import ScrollToTop from "@/components/Component"
// 路由过渡动画组件
import AnimatedSwitch from "@/components/AnimatedSwitch"

function App() {
  window.onbeforeunload = function () {
    document.documentElement.scrollTop = 0;  //ie下
    document.body.scrollTop = 0;  //非ie
  }
  return (
    <Router>
      <div className="App">
        <Pagehead></Pagehead>
        <ScrollToTop>
          <AnimatedSwitch>
            <Route
              path='/'
              exact
              render={() => {
                return (
                  <Redirect to='/Home'></Redirect>
                )
              }}></Route>
            <Route exact path='/home' component={Home}></Route>
            <Route exact path='/payment' component={Payment}></Route>
            <Route exact path='/solution' component={Solution}></Route>
            <Route exact path='/notice' component={Notice}></Route>
            <Route exact path='/intelligent' component={IntelligentDevice}></Route>
          </AnimatedSwitch>
        </ScrollToTop>
        <Pagebottom></Pagebottom>
      </div>
      
    </Router>

  );
  
}

export default App;
